const isServer = typeof window === `undefined`

export function isAsyncComponentFactory(componentOrFactory) {
  return Object.prototype.toString.call(componentOrFactory) === '[object Function]'
}

export function resolveComponent(componentOrFactory) {
  if (isAsyncComponentFactory(componentOrFactory)) {
    return componentOrFactory().then(componentModule => componentModule.default)
  }
  return componentOrFactory
}

export function makeNonce({ component, hydrationPromise }) {
  if (isServer) return component
  return () => hydrationPromise.then(() => resolveComponent(component))
}
