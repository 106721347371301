import { makeHydrationBlocker } from './utils/hydration-blocker'

function handleCustomProps(customProps) {
  const propsMap = {
    string: {
      type: String,
      default: (() => {})
    },
    number: {
      type: Number,
      default: 0
    },
    object: {
      type: Object,
      default: (() => {})
    },
    array: {
      type: Array,
      default: (() => {})
    },
    boolean: {
      type: Boolean,
      default: (() => {})
    }
  }
  const props = {}
  for(const [key, val] of Object.entries(customProps)) {
    props[key] = propsMap[val]
  }
  return props
}

export default function lazyComponent({
  componentFactory,
  customProps = {}
}) {
  const props = handleCustomProps(customProps)
  return makeHydrationBlocker(componentFactory, {
    props
  })
}
