var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.couponList.length)?_c('div',{staticClass:"three-coupon__package"},[_c('div',{staticClass:"three-coupon__package_container",style:({    
        background: _vm.compBg.colorType == 1 ? _vm.compBg.color : `linear-gradient(to right, ${_vm.compBg.startColor}, ${_vm.compBg.endColor})`,
        color: _vm.couponBg.textColor
      })},[(_vm.couponStatus === 1)?_c('div',{directives:[{name:"tap",rawName:"v-tap",value:(_vm.getTapAnalysis({})),expression:"getTapAnalysis({})"},{name:"expose",rawName:"v-expose",value:({
          id: '2-22-53',
          data: {
            compIndex: _vm.index, 
            index: 1,          
            sceneData: _vm.sceneData,
            propData: _vm.propData,
            item_loc: _vm.index + 1,
            act_nm: _vm.couponStatus - 1, 
            couponList: _vm.couponList,           
          }
        }),expression:"{\n          id: '2-22-53',\n          data: {\n            compIndex: index, \n            index: 1,          \n            sceneData,\n            propData,\n            item_loc: index + 1,\n            act_nm: couponStatus - 1, \n            couponList,           \n          }\n        }"}],staticClass:"coupon-package__inner-collect",on:{"click":_vm.clickCollect}},[_c('div',{staticClass:"coupon-package__inner-left"},_vm._l((_vm.couponList),function(coupon){return _c('div',{key:coupon?.couponId,staticClass:"coupon-package",style:({
              background: _vm.couponBg.bgColor,
            })},[_c('div',{staticClass:"coupon-package__desc"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-around","width":"100%"}},_vm._l((coupon?.coupon_rule?.slice(0,3)),function(rule){return _c('div',{key:rule.id,staticClass:"discount-wrapper",style:(_vm.collectCouponWidth(coupon?.coupon_rule?.slice(0,3)))},[_c('p',{ref:"couponText",refInFor:true,staticClass:"discount"},[_c('ResizeFont',[_c('ResizeFontText',{staticClass:"value",attrs:{"init-font-size":15,"resize-font-min":9,"resize-font-step":1,"content":_vm.formatBenefitsText(coupon, rule)}})],1)],1),_vm._v(" "),_c('p',{staticClass:"amount",style:({
                      color: _vm.hexToRGBA(_vm.couponBg.textColor,0.7)
                    })},[_vm._v("\n                    "+_vm._s(_vm.template(rule?.min?.amountWithSymbol, _vm.compLanguage.SHEIN_KEY_PWA_27736))+"\n                  ")])])}),0),_vm._v(" "),_c('div',{staticClass:"requirement",style:({              
                  borderTop: (_vm.couponStatus === 1) ? `1px dashed ${_vm.hexToRGBA(_vm.couponBg.textColor, 0.25)}` : 'none'
                })},[_vm._v("\n                "+_vm._s(_vm.isFreeshippingCoupon(coupon) ? _vm.compLanguage.SHEIN_KEY_PWA_29178 : _vm.showAllOrPart(coupon) ? _vm.compLanguage.SHEIN_KEY_PWA_27735 : _vm.compLanguage.SHEIN_KEY_PWA_27734)+"\n                "),_c('div',{staticClass:"coupon-package__circle-left",style:({
                    backgroundColor: _vm.compBg.color || _vm.compBg.startColor,
                  })}),_vm._v(" "),_c('div',{staticClass:"coupon-package__circle-right",style:({
                    backgroundColor: _vm.compBg.color || _vm.compBg.startColor,
                  })})])])])}),0),_vm._v(" "),_c('div',{staticClass:"coupon-package__inner-right",style:({
            backgroundImage: `url(${_vm.GB_cssRight ? _vm.couponCollect?.arBgRightImage?.src : _vm.couponCollect?.bgRightImage?.src})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
          })},[_c('div',{staticClass:"collect",style:({
              color: _vm.buttonTextColor,
              background: `${ _vm.couponCollect?.buttonBgColor?.colorType == 1 ? _vm.couponCollect?.buttonBgColor?.color : `linear-gradient(to right, ${_vm.couponCollect?.buttonBgColor?.startColor}, ${_vm.couponCollect?.buttonBgColor?.endColor})`}`,
            })},[_vm._v("\n            "+_vm._s(_vm.compLanguage.SHEIN_KEY_PWA_27737)+"\n          ")])])]):_vm._e(),_vm._v(" "),(_vm.couponStatus === 2 || _vm.couponStatus === 3)?_c('div',{staticClass:"coupon-package__inner-use",style:({      
          color: _vm.couponBg.textColor
        })},_vm._l((_vm.couponList),function(coupon,index){return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
            id: '2-22-53',
            data: {
              compIndex: index, 
              index,          
              sceneData: _vm.sceneData,
              propData: _vm.propData,
              item_loc: index + 1,
              act_nm: _vm.couponStatus - 1,           
              couponList: [coupon],             
            }
          }),expression:"{\n            id: '2-22-53',\n            data: {\n              compIndex: index, \n              index,          \n              sceneData,\n              propData,\n              item_loc: index + 1,\n              act_nm: couponStatus - 1,           \n              couponList: [coupon],             \n            }\n          }"},{name:"tap",rawName:"v-tap",value:(_vm.getTapAnalysis({ coupon, index })),expression:"getTapAnalysis({ coupon, index })"}],key:coupon.couponId || coupon.id,staticClass:"coupon-package__item",style:({
            background: _vm.couponBg?.bgColor,     
          }),on:{"click":function($event){return _vm.clickCoupon(coupon)}}},[_c('div',{staticClass:"coupon-package__inner-left",style:({
              borderRight: (_vm.couponStatus === 2 || _vm.couponStatus === 3) ? `1px dashed ${_vm.hexToRGBA(_vm.couponBg.textColor, 0.25)}` : 'none'
            })},[_c('div',{staticClass:"coupon-package__desc"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-around","width":"100%"}},_vm._l((coupon?.coupon_rule?.slice(0,3)),function(rule){return _c('div',{key:rule.id || rule.coupon_range_id,staticClass:"discount-wrapper",style:(_vm.useCouponWidth(coupon?.coupon_rule?.slice(0,3)))},[_c('p',{ref:"couponText",refInFor:true,staticClass:"discount"},[_c('ResizeFont',[_c('ResizeFontText',{staticClass:"value",attrs:{"init-font-size":15,"resize-font-min":9,"resize-font-step":1,"content":_vm.formatBenefitsText(coupon, rule)}})],1)],1),_vm._v(" "),_c('p',{staticClass:"amount",style:({
                      color: _vm.hexToRGBA(_vm.couponBg.textColor,0.7)
                    })},[_vm._v("\n                    "+_vm._s(_vm.template(rule?.min?.amountWithSymbol, _vm.compLanguage.SHEIN_KEY_PWA_27736))+"\n                  ")])])}),0),_vm._v(" "),_c('div',{staticClass:"requirement",style:({
                  backgroundColor: `${_vm.hexToRGBA(_vm.couponBg.textColor, 0.05)}`,
                })},[_vm._v("\n                "+_vm._s(_vm.isFreeshippingCoupon(coupon) ? _vm.compLanguage.SHEIN_KEY_PWA_29178 : _vm.showAllOrPart(coupon) ? _vm.compLanguage.SHEIN_KEY_PWA_27735 : _vm.compLanguage.SHEIN_KEY_PWA_27734)+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"coupon-package__circle-top",style:({
                backgroundColor: _vm.compBg.color || _vm.compBg.startColor,
              })}),_vm._v(" "),_c('div',{staticClass:"coupon-package__circle-bottom",style:({
                backgroundColor: _vm.compBg.color || _vm.compBg.startColor,
              })})]),_vm._v(" "),_c('div',{staticClass:"coupon-package__inner-right"},[(!(_vm.couponStatus === 3))?[_c('div',{staticClass:"btn-use",style:({
                  color: _vm.buttonTextColor,
                  background: `${ _vm.couponUse?.buttonBgColor?.colorType == 1 ? _vm.couponUse?.buttonBgColor?.color : `linear-gradient(to right, ${_vm.couponUse?.buttonBgColor?.startColor}, ${_vm.couponUse?.buttonBgColor?.endColor})`}`,
                })},[_vm._v("\n                "+_vm._s(_vm.compLanguage.SHEIN_KEY_PWA_28298)+"\n              ")]),_vm._v(" "),(_vm.showExpireText(coupon))?_c('div',{staticClass:"expire"},[_vm._v("\n                "+_vm._s(_vm.compLanguage.SHEIN_KEY_PWA_27838)+"\n              ")]):_vm._e()]:[_c('p',{staticClass:"code",style:({
                  color: _vm.couponBg.textColor
                })},[_vm._v("\n                "+_vm._s(_vm.compLanguage.SHEIN_KEY_PWA_27207)+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"code-value",style:({
                  color: _vm.codeTextColor,
                  backgroundColor: _vm.couponBg.textColor,
                })},[_vm._v("\n                "+_vm._s(coupon.couponCode)+"\n              ")])]],2)])}),0):_vm._e()])]):_vm._e(),_vm._v(" "),_c('ClientOnly',[_c('coupon-dialog',{attrs:{"language":_vm.compLanguage,"scene":"home","show":_vm.showCouponDialog,"coupon-list":_vm.hasReceived ? [] : _vm.couponList},on:{"onCouponClose":()=>{ _vm.showCouponDialog = false }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }