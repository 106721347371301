import lazyComponent from 'public/src/services/lazyComponent/lazy-hydrate' // 组件懒加载

const componentsMap = {
  // 轮播图
  'SideslipImage': () => import(/* webpackChunkName: "store-sideslip-image" */'./sideslip-image/Index.vue'),
  // 单图 双图 三图 四图
  'StaticImageCardController': () => import(/* webpackChunkName: "store-static-image" */'./static-image/StaticImageFactory.js'),
  // 一行三品牌
  'BrandRecommend': () => import(/* webpackChunkName: "store-brand-recommend" */'./brand-recommend/index'),
  // 店铺品类推荐
  'StoreCategoryDisplayRecommend': () => import(/* webpackChunkName: "store-category-display-recommend" */'./store-category-display-recommend/store-category-display-recommend.vue'),
}

const components = Object.entries(componentsMap).reduce((prev, cur) => {
  prev[`${cur[0]}`] = lazyComponent({
    // propWhole: true,
    componentFactory: cur[1],
    customProps: {
      index: 'number',
      propData: 'object',
      propHooks: 'object',
      content: 'array',
      context: 'object',
      sceneData: 'object',
      cateLinks: 'object',
      language: 'object',
      brand: 'string',
      isLast: 'boolean',
      isLoad: 'boolean',
      lazyForceShow: 'boolean'
    }
  })
  if (['HorizontalAnchor', 'HorizontalAnchorImage', 'Welfare'].includes(cur[0])) {
    prev[`${cur[0]}`] = cur[1]
  }
  return prev
}, {})

export default components
