<!-- eslint-disable @shein-aidc/cccx/notUseImgTag -->
<template>
  <div class="shop-banner__prefer-seller">
    <i
      v-if="labelIcon"
      class="shop-banner__prefer-seller-icon"
      :style="{
        background: `url(${labelIcon}) 50% 50% / contain no-repeat`
      }"
    ></i>
    <span>{{ labelText }}</span>
  </div>
</template>

<script>
export default {
  name: 'PreferredSellerLabel',
}
</script>

<script setup>
defineProps({
  labelIcon: {
    type: String,
    default: '',
  },
  labelText: {
    type: String,
    default: '',
  },
})
</script>

<style lang="less" scoped>
.shop-banner__prefer-seller {
  display: flex;
  align-items: center;
  height: 16/37.5rem;
  border: .5px solid rgba(168, 97, 4, 0.15);
  border-radius: 2/37.5rem;
  color: #A86104;
  background-color: #FFF8EB;
  padding: 0 3/37.5rem;
  white-space: nowrap;
  .shop-banner__prefer-seller-icon {
    display: block;
    width: 12/37.5rem;
    height: 12/37.5rem;
    margin-right: 2/37.5rem;
  }
  span {
    font-size: 10px;
    font-family: 'SF-UI-Text-Regular';
    line-height: 1.2;
  }
}
</style>
