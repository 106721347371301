<!-- eslint-disable @shein-aidc/cccx/notUseImgTag -->
<template>
  <div class="store-banner-component store-banner-component__lastest">
    <div 
      v-expose="getAnalysisData(exposeId, { item, index: 0 })"
      :class="[
        'branch-shop-banner', 
        {
          'branch-shop-banner__nobottom': isNoBottomContent,
        },
      ]"
    >
      <section class="branch-shop-banner__header">
        <div
          v-if="shopData.logo"
          class="branch-shop-banner__header-logo"
        >
          <BaseImg
            :first-screen="true"
            :is-support-webp="true"
            :img-design-width="80"
            :img-src="shopData.logo.replace(/^https?:/, '')"
          />
        </div>
        <div
          class="branch-shop-banner__header-detail"
        >
          <div class="branch-shop-banner__header-detail-box">
            <div class="branch-shop-banner__header-detail-boxleft">
              <div class="branch-shop-banner__header-detail-top">
                <div
                  v-if="shopData.title"
                  class="branch-shop-banner__header-detail-title fsp-element"
                >
                  <span
                    class="text-ellipsis"
                    style="font-size:0.37rem"
                  >{{ shopData.title }}</span>
                </div>
              </div>
              <DataLabels
                class="branch-shop-banner__header-detail-top"
                :constants-data="constantsData"
                :store-banner-lang="storeBannerLang"
                :shop-data="shopData"
                :formatted-data="formattedData"
              />
            </div>
            <StoreFollowBtn
              class="branch-shop-banner__header-follow"
              v-bind="$props"
            >
              <template
                slot="button"
                slot-scope="props"
              >
                <button 
                  :class="[
                    'follow_btn',
                    props.shopData?.followsStatus ? 'follow_btn1' : 'follow_btn2',
                  ]"
                > 
                  {{ props.shopData?.followsStatus ? '' : '+' }} {{ getFollowBtnText(props.shopData) }}
                </button>
              </template>
            </StoreFollowBtn>
          </div>
          <div
            class="branch-shop-banner__header-detail-info fsp-element"
          >
            <div
              v-if="shopData.score"
              v-tap="getAnalysisData('2-22-2', { item, index: 0 })"
              class="branch-shop-banner__header-detail-info__rating"
              @click="hrefLink()"
            >
              <span class="text-ellipsis">{{ storeBannerLang.ratingLang }}</span>
              <span style="margin-right: 2px;">:</span>
              <b>{{ formatShopScore }}</b>
              <i
                v-if="!hiddenCommentEntry"
                class="suiiconfont sui_icon_more_right2_16px"
              ></i>
            </div>
            <div
              v-if="shopData.salesCount && shopData.salesCount !== '0'"
              class="branch-shop-banner__header-detail-info__product"
            >
              <span class="text-ellipsis">{{ storeBannerLang.salesCountLang }}</span>
              <span style="margin-right: 2px;">:</span>
              <b>{{ formatterValue(shopData.salesCount) }}</b>
            </div>
            <div
              v-if="shopData.quantity"
              class="branch-shop-banner__header-detail-info__product"
            >
              <span class="text-ellipsis">{{ storeBannerLang.itemsLang }}</span>
              <span style="margin-right: 2px;">:</span>
              <b>{{ formattedData.storeGoodsQuantity }}</b>
            </div>
            <div
              v-if="shopData.followsCount && shopData.followsCount !== '0'"
              class="branch-shop-banner__header-detail-info__product"
            >
              <span class="text-ellipsis">{{ storeBannerLang.followsCountLang }}</span>
              <span style="margin-right: 2px;">:</span>
              <b>{{ shopData.followsCount }}</b>
            </div>
          </div>
        </div>
      </section>
      <!-- 服务标签和描述 -->
      <ServicePolicyLabels
        :constants-data="constantsData"
        :shop-data="shopData"
        :formatted-data="formattedData"
        @show-description-drawer="showDescriptionDrawer"
      />
      <!-- 描述点击 -->
      <div
        v-if="isShowDescriptions"
        class="branchDescriptionWrap"
        @click="openDescDrawer()"
      >
        <div
          ref="branchDescriptionContent"
          class="branchDescriptionWrap__content"
        >
          {{ shopData.descriptions }}
        </div>
        <svg
          v-if="showDescriptionIcon"
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
        >
          <path
            d="M4.5 2.96458L8.03553 6.50011L4.5 10.0356V10.0356"
            stroke="#959595"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixins from '../mixins/mixin'
import StoreFollowBtn from './widgets/StoreFollowBtn.vue'
import ServicePolicyLabels from './widgets/ServicePolicyLabels.vue'
import DataLabels from './widgets/DataLabels.vue'

const { PUBLIC_CDN = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default {
  name: 'NormalShopBanner',
  components: {
    BaseImg,
    StoreFollowBtn,
    ServicePolicyLabels,
    DataLabels,
  },
  mixins: [mixins],
  props: {
    ...commonProps,
    formattedData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      PUBLIC_CDN,
      showDescriptionIcon: false, // 描述省略下拉
      constantsData: {
        PUBLIC_CDN,
      },
    }
  },
  computed: {
    /**
     * 店招组件配置项
     * @param {Object} shopData
     *    @param {Number} storeCode 店铺码
     *    @param {String} logo 店铺logo链接
     *    @param {String} title 店铺名称
     *    @param {String} descriptions 店铺介绍
     *    @param {Array} labels 优选标签
     *    @param {Number} quantity 商品数
     *    @param {String} score 店铺评分
     *    @param {String} link 链接
     *    @param {Number} storeBusinessType 店铺类型:普通1、品牌集成2
     *    @param {String} brandsellersignboard 店铺实验：品牌新样式'new'、旧样式'old'
     * 
     * */
    shopData() {
      return this.propData?.storeBanner || {}
    },
    storeAbtResult(){
      return this.context?.storeAbtResult || {}
    },
    isNoBottomContent(){
      // 没有服务保障标签和描述时
      return !this.formattedData?.showServicePolicyLabel && !this.shopData?.descriptions
    },
    storeBannerLang() {
      return this.context?.language?.storeBanner || {}
    },
    formatShopScore() {
      return this.formattedData?.storeScores
    },
    item() {
      return this.formattedData?.cccAnalysisInfo ?? {}
    },
    exposeId() {
      return this.formattedData?.isCCCshopBanner ? '2-22-1' : '2-22-77'
    },

    salesArea() {
      return this.shopData?.salesArea || null
    },
    hiddenCommentEntry({ sceneData }) {
      return !!sceneData?.hiddenCommentEntry
    },
    isShowDescriptions(){
      return !!this.formattedData?.isShowDescriptions
    }

  },
  mounted() {
    this.init()
    this.$refs.bannerBG?.resetFalconlazy()
  },
  methods: {
    async init() {
      this.$nextTick(()=>{
        this.handleResize()
        window.addEventListener('resize', this.handleResize)
      })
    },
    hrefLink() {
      const { langPath } = gbCommonInfo
      const isLinkToComment = this.shopData?.link?.includes?.('/store-comment?shop_id=')
      if (isLinkToComment && this.hiddenCommentEntry) return
      if(this.shopData.link) this.$router.push( langPath + this.shopData.link) 
    },
    // 打开描述
    openDescDrawer() {
      if(!this.showDescriptionIcon) return
      this.showDescriptionDrawer()
    },
    // resize method
    handleResize() {
      const contentRef = this.$refs.branchDescriptionContent
      if(!contentRef) return
      this.showDescriptionIcon = contentRef.clientWidth < contentRef.scrollWidth
    },

    /**
     * 
     * 收藏数<1k，返回原值:
     * 收藏数>=1k且<10k，返回1K/1.1K/1.2K.....9.9K:
     * 收藏数>=10k且<99k，10K/11K/12K.....99K
     * 收藏数>=99k，返99K+; 
     */
    formatterValue(value) {
      if (value < 1000) {
        return value
      } else if (value < 10000) {
        return `${Math.floor(value / 100) / 10}K`
      } else if (value < 99000) {
        return `${Math.floor(value / 1000)}K`
      } else {
        return '99K+'
      }
    },
    showDescriptionDrawer() {
      this.$emit('show-description-drawer')
    },
    getFollowBtnText(shopData) {
      return shopData?.followsStatus
        ? this.storeBannerLang?.followedLang
        : this.storeBannerLang?.followLang
    },
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern  */
.store-banner-component {
  position: relative;
  overflow: hidden;
  padding: 0 0.32rem;
  .text-ellipsis {
    white-space: nowrap;   /* 不换行 */
    overflow: hidden;     /* 隐藏溢出部分 */
    text-overflow: ellipsis;   /* 溢出部分显示为省略号 */
  }
  .branch-shop-banner__header-detail-info__rating>b{
    font-weight: 500;
  }
  .branch-shop-banner__header-detail-info__product>b{
    font-weight: 500;
  }
}
// 品牌集成店铺
.branch-shop-banner {
  position: relative;
  overflow: hidden;
  height: 100%;
  padding: .16rem /* 6/37.5 */ 0;
  &__header {
    .flexbox();
    flex-direction: row;
    position: relative;
    align-items: center;
  }
  &__header-logo {
    border-radius: 2/37.5rem;
    width: 104/75rem;
    height: 104/75rem;
    margin-right: 16/75rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink:0;
    overflow: hidden;
    background-color: #fff;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__header-detail {
    .flexbox();
    flex: 1;
    font-family: Helvetica,Arial,sans-serif;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }
  &__header-detail-box {
    display: flex;
    flex-direction: row;
  }
  &__header-detail-boxleft {
    flex: 1 1;
    overflow: hidden;
  }
  &__header-detail-top {
    .flexbox();
    align-items: center;
    flex-wrap: wrap;
    line-height: 40/75rem;
  }
  &__header-detail-title {
    .font-dpr(34px);
    font-weight: 700;
    // text-transform: uppercase;
    color: #fff;
    // margin-right: 8/75rem;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
  }
  &__header-detail-info {
    .flexbox();
    .font-dpr(22px);
    align-items: center;
    color: #fff;
    font-weight: 400;
    margin-top: 10/75rem;
    &__rating { /* stylelint-disable-line */
      .flexbox();
      .font-dpr(20px);
      margin-right: .32rem /* 12/37.5 */;
      align-items: center;
      line-height: 26/75rem;
      display: flex;
      >span{
        color: rgba(255, 255, 255, 0.90);
      }
      > span:first-child {
        flex: 1;
      }
      > i {
        vertical-align: middle;
        text-align: left;
      }
    }
    &__line { /* stylelint-disable-line */
      width: 1/75rem;
      height: 22/75rem;
      vertical-align: text-top;
      margin: 0 12/75rem 0 6/75rem;
      background-color: rgba(255, 255, 255, 0.6);
    }
    &__product { /* stylelint-disable-line */
      .flexbox();
      .font-dpr(20px);
      align-items: center;
      line-height: 26/75rem;
      margin-right: .32rem /* 12/37.5 */;
      overflow: hidden;
      display: flex;
      >span{
        color: rgba(255,255,255, 0.90);
      }
      > span:first-child {
        flex: 1;
      }
    }
  }
  &__header-follow {
    flex-shrink: 0;
    margin-left: 4px;
    align-self: center;
    // position: absolute;
    // float: right;
    // top: 0;
    // right: 0;
  }
}
// 描述
.branchDescriptionWrap {
  position: relative;
  .font-dpr(24px);
  .flexbox();
  align-items: center;
  font-family: Helvetica,Arial,sans-serif;
  font-style: normal;
  color: #fff;
  width: 100%;
  padding-top: 20/75rem;
  line-height: 28/75rem;
  &__content {
    display: inline-block;
    width:calc(100% - 25px);
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: text-bottom;
  }
  &__contentCopy {
    display: inline-block;
    visibility: hidden;
  }
  /** 扩大点击区域 */
  &:after {
    content: "";
    position: absolute;
    top: -.1rem;
    left: -.1rem;
    right: -.1rem;
    bottom: -.2rem;
  }
}
.branch-shop-banner{
  .branchDescriptionWrap{
    padding-top: .16rem /* 6/37.5 */;
    &__content {
      width:calc(100% - 12px);
    }
  }
}

// 展示新样式的普通店铺
.branch-shop-banner.black-color {
  .branch-shop-banner {
    &__header-detail-title {
      color: @sui_color_gray_dark1;
    }
    &__header-detail-info {
      color: rgba(0, 0, 0, 0.80);
    }
  }
  .branchDescriptionWrap {
    &__content {
      color: @sui_color_gray_dark3;
    }
  }
}

// 修改drawer的样式
.store-banner-drawer {
  box-sizing: border-box;
  .S-drawer__body {
    width: 100%;
    font-family: Helvetica,Arial,sans-serif;
    font-style: normal;
    padding: 24/75rem;
    font-size: 24/75rem;
    color: #000;
    line-height: 32/75rem;
    box-sizing: border-box;
    > p{
      width: 100%;
      width:100%;
      word-wrap:break-word;
      white-space: normal;
    }
  }
}

.store-banner-component__lastest{
  .branch-shop-banner{
    padding: .213333rem /* 8/37.5 */ 0 .16rem /* 6/37.5 */;
  }
  .branch-shop-banner__nobottom{
    padding: .213333rem /* 8/37.5 */ 0;
  }
  .branch-shop-banner__header-detail-info__rating>b{
    font-size: .32rem /* 12/37.5 */;
    font-weight: 500;
  }
  .branch-shop-banner__header-detail-info__product>b{
    font-size: .32rem /* 12/37.5 */;
    font-weight: 500;
  }
  .branchDescriptionWrap{
    padding-top: .16rem /* 6/37.5 */;
  }
  .branchDescriptionWrap__content{
    font-size: 10px;
    color: #959595;
  }
}
</style>
<style lang="less" scoped>
.tag_wrap{
  overflow: hidden; 
  max-height: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.follow_btn{
  padding: 5px 8px;
  line-height: 28/75rem;
  font-size: 24/75rem;
  font-weight: 400;
  border: none;
  max-width: 2.7rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.follow_btn1{
  // 已关注
  color: #CCC;
  background-color: rgba(255, 255, 255, 0.12);
}
.follow_btn2{
  // 未关注
  color: #000;
  background-color: #FFF;
}
.follow_btn3{
  // 已关注
  color: #959595;
  background-color: #F6F6F6;
  &.followed {
    background-color: #E5E5E566;
  }
}
.follow_btn4{
  // 未关注
  color: #fff;
  background-color: #000;
}
</style>
