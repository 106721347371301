export const COUPON_DIMENSION = {
  // 平台券
  Platform: '1',
  // mall券
  Mall: '2',
  // 店铺券
  Store: '3',
}

export const COUPON_APPLY_FOR = {
  // 全部商品
  AllProducts: '1',
  // 部分商品
  SomeProducts: '2',
  // 部分品类
  SomeCategories: '3',
  // 减最低邮费
  MinimumPostageReduced: '5',
  // 免邮券
  FreeShipping: '6',
  // 选品页面
  ProductSelectionPage: '7',
  // 部分店铺的平台券
  SomeStoresFromPlatform: '8',
  // 指定运输方式减运费
  MinusFreightOnSpecificTransport: '9',
  // 会费券
  ClubCoupon: '10',
  // ab 价伪装券
  ABPrice: '101',
}

export const COUPON_OFF_TYPE = {
  // 减元
  Cash: '1',
  // 应付价折扣
  PercentOfPriceShouldPay: '5',
  // 原价折扣
  PercentOfOriginPrice: '2',
}

export const STORE_CODE = {
  // 官方自营
  OfficialSelfManagement: '1',
}

export const PLATFORM_STORE_TYPE = {
  // 自营品类
  SelfManagement: '4',
}

export const MAX_COUPON_STEPS_SHOW = 3

// 券包弹窗类型
export const COUPON_DIALOG_TYPE = {
  CouponPackage: 'couponPackage',
  CouponRemind: 'couponRemind',
}

// 首页劵包支持的类型
export const PACKAGE_COUPON_TYPE = {
  HOME_PAGE_COUPON: '23',
  CLUB_COUPON: '58',
  SAVER_COUPON: '59',
  NEW_USER_COUPON: '21',
  REMIND_SAVER_COUPON: '55',
  REMIND_CLUB_COUPON: '53',
}

// 劵包劵类型
export const COUPON_TYPE = {
  // 用增劵包
  57: '1',   
  // 首页劵包
  23: '2', 
  // club会费券
  58: '3', 
  // saver会费券
  59: '4',
  // 首单券
  2: '5',
  // 下单返券
  60: '6',
  // 新人券
  21: '7',
  // 运费券
  61: '8',
  // 提醒弹窗saver券
  55: '9',
  // 低购频券包券
  66: '12',
}

// 券包埋点 act_id 类型
// act_id：区分
// 0-关闭
// 1-底部button（ok 或 collect all）
// 2-跳转券列表（check coupon）
// 3-选品链接
// 4-use按钮
// 5-膨胀券按钮点击
export const COUPON_BA_ACT_ID = {
  CLOSE: 0,
  BOTTOM_BUTTON: 1,
  CHECK_COUPON: 2,
  PRODUCT_SELECT_LINK: 3,
  USE_BUTTON: 4,
  EXPANSION_COUPON_BUTTON: 5,
}

// 绑券结果类型
export const BIND_COUPON_STATUS = {
  PARTIAL_SUCCESS: '接口绑券部分成功-611',
  ALL_SUCCESS: '接口全部绑上-612',
  ALL_FAILED: '全部绑券失败-613',
}
