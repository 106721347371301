import schttp from 'public/src/services/schttp'

export const createStoreFollow = async (data) => {
  try {
    return await schttp({
      url: '/api/store/follow/create',
      method: 'POST',
      data,
      timeout: 10 * 1000
    }) 
  } catch(e) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

export const deleteStoreFollow = async (data) => {
  try {
    return await schttp({
      url: '/api/store/follow/delete',
      method: 'POST',
      data,
      timeout: 10 * 1000
    }) 
  } catch(e) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

export const getStoreFollowsCount = async (data) => {
  try {
    return await schttp({
      url: '/api/store/followsCount/query',
      method: 'POST',
      data,
      timeout: 10 * 1000
    }) 
  } catch(e) {
    return Promise.resolve({
      code: -1,
      msg: 'error request'
    })
  }
}

/**
 * 批量查询品牌详情ToC
 */
export const getCccBatchFindBrand = (data = {}) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/batchFindBrand/get',
    data,
  })
}

/**
 * 通过品牌code获取选品信息
 */
export const getCccSelectByBrandCodes = (data = {}) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/selectByBrandCodes/get',
    data,
  })
}

/**
 * 通过品牌code获取品牌和选品信息
 */
export const getCccBrandAndPickingInfo = (data = {}) => {
  return schttp({
    method: 'POST',
    url: '/api/ccc/brandAndPickingInfo/query',
    data,
  })
}
