/**
 * !!!公共属性，注意是否真的是公共，如果只是单独某个组件使用可以单独在自己组件里面额外扩展，毋放在公共属性位置
 */

export const commonProps = {
  // 组件的位置
  index: {
    type: Number,
    default: 0
  },
  propData: {
    type: Object,
    default: () => ({}),
  },
  propHooks: {
    type: Object,
    default: () => (null),
  },
  sceneData: {
    type: Object,
    default: () => ({}),
  },
  cateLinks: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  context: {
    type: Object,
    default: () => ({})
  },
  content: {
    type: Array,
    default: () => ([])
  },
  brand: {
    type: String,
    default: 'shein',
  },
  isLoad: {
    type: Boolean,
    default: false
  },
  lazyForceShow: {
    type: Boolean,
    default: false
  },
  cropRate: {
    type: String,
    default: '3-4',
  }
}
