import { ref, onMounted, onUnmounted } from 'vue'
import { abtservice } from 'public/src/services/abt'

const NEED_UPDATE_ABT_POSKEYS = [
  'followenhance',
]

export const initFollowStoreAfterLogin = ({
  initAbt,
} = {}) => {
  const abt = ref(initAbt)
  const updateAbtOnLogin = async () => {
    // eslint-disable-next-line @shein-aidc/abt/abt
    const result = await abtservice.getUserAbtResult({
      posKeys: '',
      newPosKeys: NEED_UPDATE_ABT_POSKEYS.join(),
    })
    abt.value = {
      ...abt.value,
      ...result,
    }
  }
  onMounted(() => {
    // 注册 登录后关注回调
    appEventCenter?.$on('store_pages:followStoreAfterLogin', async (followParams) => {
      await updateAbtOnLogin()
      // 重新触发关注
      setTimeout(() => {
        appEventCenter.$emit('FlowStoreFolloingClickFollow', followParams)
      }, 3e2)
    })
  })
  onUnmounted(() => {
    appEventCenter?.$off('store_pages:followStoreAfterLogin')
  })
  return {
    abt,
  }
}
