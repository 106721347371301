import ClientOnly from 'vue-client-only'
import lazyComponent from 'public/src/services/lazyComponent/lazy-hydrate' // 组件懒加载

const componentsMap = {
  // 店铺横向卡片列表
  'StaticImage': () => import(/* webpackChunkName: "bff-static-image" */'../home-bff-components/static-image/StaticImageFactory'),  
  'StaticImageCardController': () => import(/* webpackChunkName: "bff-static-image" */'../home-bff-components/static-image-card-controller/index.vue'),  

  'SideslipImage': () => import(/* webpackChunkName: "bff-sideslip-image" */'../home-bff-components/sideslip-image-static/index.vue'),
  // 动态组件 - 横滑频道入口
  'HorizontalSlideChanelDynamic': () => import(/* webpackChunkName: "bff-horizontal-slide-chanel-dynamic" */'../home-bff-components/horizontal-slide-chanel-dynamic/HorizontalSlideChanelDynamic.vue'),
  'HorizontalSlideChanelDynamicNew': () => import(/* webpackChunkName: "bff-horizontal-slide-chanel-dynamic-new" */'../home-bff-components/horizontal-slide-chanel-dynamic-new/HorizontalSlideChanelDynamicNew.vue'),
  'MultiLine': () => import(/* webpackChunkName: "bff-multi-line" */'../home-bff-components/multi-line/Index.vue'),

  // 沉浸式banner
  'ImmersiveBanner': () => import(/* webpackChunkName: "bff-immersive-banner" */'../home-bff-components/immersive-banner/Index.vue'),
  // 免邮
  'FreeShippingInfo': () => import(/* webpackChunkName: "bff-free-shipping" */'../home-bff-components/free-shipping/index.vue'),

  // 横向滑动商品组件
  'HorizontalItems': () => import(/* webpackChunkName: "bff-horizontal-items" */'../home-bff-components/horizontal-items/Index.vue'),
  
  // 三档券
  'CouponPackage': () => import(/* webpackChunkName: "bff-coupon-package" */'../home-bff-components/coupon-package/Index.vue'),
}

const components = Object.entries(componentsMap).reduce((prev, cur) => {
  prev[`${cur[0]}`] = lazyComponent({
    // propWhole: true,
    componentFactory: cur[1],
    customProps: {
      index: 'number',
      propData: 'object',
      propHooks: 'object',
      content: 'array',
      context: 'object',
      sceneData: 'object',
      cateLinks: 'object',
      language: 'object',
      brand: 'string',
      isLast: 'boolean',
      isLoad: 'boolean',
      lazyForceShow: 'boolean'
    }
  })
  if (['HorizontalAnchor', 'HorizontalAnchorImage', 'Welfare'].includes(cur[0])) {
    prev[`${cur[0]}`] = cur[1]
  }
  return prev
}, {})
components['ClientOnly'] = ClientOnly

export default components
