
// import TMG from '@shein/time-management-guru'
// import { hasCookie } from '@shein/common-function'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { preFetchOrGetPageUrl } from './utils.js'
import { CccLink } from 'public/src/pages/components/ccc/common/ccc-link.js'
// function preLoadHtml({ url, relType = 'prefetch', as = 'document' }) {
//   return new Promise((resolve, reject) => {
//     let link = document.createElement('link')
//     link.rel = relType
//     link.href = url
//     link.as = as
//     link.onload = (event) => {
//       resolve(event)
//     }
//     link.onerror = (event) => {
//       reject({
//         event,
//         url
//       })
//     }
//     document.head.appendChild(link)
//   })
// }

export async function preFetch(vm) {
  const props = vm?.propData?.props || {}
  const items = props?.items
  const hasCampaigns = items?.some(item => item?.hrefType === 'activityNew')
  // el 必须是渲染完的
  if (!hasCampaigns) return
  prefetchResource.listen({
    el: vm.$el,
    prefetchList: [
      {
        chunkName: 'ccc-campaigns',
        relType: 'prefetch'
      }
    ],
    prefetchCallback: ({ status, info }) => {
      console.log('prefetchCallback', status, info)
    },
    delay: 2000, // 默认两秒
    ioCallback: () => {
      const { SW_FETCH_HTML_CONFIG = {} } = window.gbCommonInfo || {}
      const { enableSceneMap = [], preFetchSceneMap = [] } = SW_FETCH_HTML_CONFIG || {}
      if (!enableSceneMap.includes('cccx') || !preFetchSceneMap.includes('cccx')) return
      const cccLink = new CccLink({ sceneData: vm.sceneData, propData: vm.propData })
      items.forEach((item, index) => {
        if (item?.hrefType === 'activityNew' && props?.componentKey !== 'RANKING_ENTRANCE') {
          const url = cccLink.getFullLink({ 
            item, 
            cateLinks: vm.cateLinks, 
            compIndex: vm.index, 
            index
          })
          // html预取
          preFetchOrGetPageUrl({ url, options: { scene: 'cccx' } })
        }
        console.log('ioCallback')
      })
    }
  })
}
