export function makeHydrationPromise(vm) {
  let hydrate = () => {}
  const hydrationPromise = new Promise((resolve) => {
    hydrate = () => {
      if(!vm.lazyForceShow) { // 直出组件不用修改状态
        vm.isLazy = false
      }
      resolve()
    }
  })

  return {
    hydrate,
    hydrationPromise,
  }
}
