<template>
  <div class="shop-banner__local-seller">
    <span>{{ labelText }}</span>
  </div>
</template>

<script>
export default {
  name: 'LocalSellerLabel',
}
</script>

<script setup>
defineProps({
  labelText: {
    type: String,
    default: '',
  },
})
</script>

<style lang="less" scoped>
.shop-banner__local-seller {
  display: flex;
  align-items: center;
  height: 16/37.5rem;
  border: .5px solid rgba(25, 128, 85, 0.15);
  border-radius: 2/37.5rem;
  color: #198055;
  background-color: #F5FCFB;
  padding: 0 3/37.5rem;
  white-space: nowrap;
  span {
    font-size: 10px;
    font-family: 'SF-UI-Text-Regular';
    line-height: 1.2;
  }
}
</style>
