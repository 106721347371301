<!-- eslint-disable @shein-aidc/cccx/notUseImgTag -->
<template>
  <div class="shop-banner__data-label shop-banner__trend-label">
    <img
      v-if="trendsLabelInfo.trendsLabelImg"
      :src="trendsLabelInfo.trendsLabelImg"
    />
    <div
      v-else-if="trendsLabelInfo.trendsLabelText"
      class="trend-label__text"
    >
      {{ trendsLabelInfo.trendsLabelText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrendLabel',
}
</script>

<script setup>
defineProps({
  trendsLabelInfo: {
    type: Object,
    default: () => ({}),
  },
})
</script>

<style lang="less" scoped>
.shop-banner__trend-label {
  height: 16/37.5rem;
  overflow: hidden;
  flex-grow: 0;
  & > img {
    display: block;
    height: 100%;
  }
  .trend-label__text {
    padding: 0 .133333rem /* 5/37.5 */;
    line-height: 1.454545;
    color: var(---sui_color_white, #FFF);
    font-size: 11px;
    font-weight: 600;
  }
}
</style>
