import { makeHydrationObserver } from './hydration-observer'
import { makeHydrationPromise } from './hydration-promise'
import { makeNonce } from './nonce'
import { preFetch } from '../pre-fetch'

export function makeHydrationBlocker(component, options = {}) {
  const result = Object.assign({
    mixins: [options],
    inheritAttrs: false,
    name: 'HydrationBlocker',
    props: {
      idleTimeout: {
        default: 2000,
        type: Number,
      },
      never: {
        type: Boolean,
      },
      onInteraction: {
        type: [Array, Boolean, String],
      },
      triggerHydration: {
        default: false,
        type: Boolean,
      },
      whenIdle: {
        type: Boolean,
      },
      whenVisible: {
        type: [Boolean, Object],
      },
      lazyForceShow: {
        type: Boolean,
        default: false
      },
      lazyWrapOptions: {
        type: Object,
        default () {
          return {}
        }
      },
      emitLoaded: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isLazy: true,
        loaded: false,
      }
    },
    watch: {
      triggerHydration: {
        immediate: true,
        handler(isTriggered) {
          if (isTriggered) this.hydrate()
        },
      },
    },
    computed: {
      interactionEvents() {
        if (!this.onInteraction) return []
        if (this.onInteraction === true) return [`focus`]
    
        return Array.isArray(this.onInteraction)
          ? this.onInteraction
          : [this.onInteraction]
      },
      placeholderOptions () {
        return Object.keys(this.lazyWrapOptions).length ? this.lazyWrapOptions : null
      }
    },
    beforeCreate() {
      this.cleanupHandlers = []
      const { hydrate, hydrationPromise } = makeHydrationPromise(this)
      this.Nonce = makeNonce({ component, hydrationPromise })
      this.hydrate = hydrate
      this.hydrationPromise = hydrationPromise
    },
    beforeDestroy() {
      this.cleanup()
    },
    created() {
      this.renderOptions = {
        ...this.placeholderOptions,
        attrs: this.$attrs || this.placeholderOptions?.attrs || {},
        on: {
          ...this.$listeners,
          lazyLoaded: () => this.onLoaded(),
          'hook:mounted': () => this.onMounted()
        },
      }
      if(this.never) {
        this.hydrate()
      }
    },
    mounted() {
      if (this.$el.nodeType === Node.COMMENT_NODE) {
        // No SSR rendered content, hydrate immediately.
        // this.hydrate()
        return
      }
  
      if (this.never) return
  
      if (this.whenVisible) {
        const observerOptions = this.whenVisible !== true ? this.whenVisible : {
          rootMargin: '20% 0px 20% 0px',
          threshold: [0],
        }
        const observer = makeHydrationObserver(observerOptions)
  
        // If Intersection Observer API is not supported, hydrate immediately.
        if (!observer) {
          this.hydrate()
          return
        }
          
        this.$el.hydrate = this.hydrate
        const cleanup = () => {
          if (this.$el.nodeType !== Node.COMMENT_NODE) {
            observer.unobserve(this.$el)
          }
        } 
        this.cleanupHandlers.push(cleanup)
        this.hydrationPromise.then(cleanup)
        observer.observe(this.$el)
        return
      }
  
      if (this.whenIdle) {
        // If `requestIdleCallback()` or `requestAnimationFrame()`
        // is not supported, hydrate immediately.
        if (!(`requestIdleCallback` in window) || !(`requestAnimationFrame` in window)) {
          this.hydrate()
          return
        }
  
        // @ts-ignore
        const id = requestIdleCallback(() => {
          requestAnimationFrame(this.hydrate)
        }, { timeout: this.idleTimeout })
        // @ts-ignore
        const cleanup = () => cancelIdleCallback(id)
        this.cleanupHandlers.push(cleanup)
        this.hydrationPromise.then(cleanup)
      }
  
      if (this.interactionEvents && this.interactionEvents.length) {
        const eventListenerOptions = {
          capture: true,
          once: true,
          passive: true,
        }
  
        this.interactionEvents.forEach((eventName) => {
          this.$el.addEventListener(eventName, this.hydrate, eventListenerOptions)
          const cleanup = () => {
            this.$el.removeEventListener(eventName, this.hydrate, eventListenerOptions)
          }
          this.cleanupHandlers.push(cleanup)
        })
      }
    },
    // activated() {
    //   preFetch(this)
    // },
    methods: {
      cleanup() {
        this.cleanupHandlers.forEach(handler => handler())
      },
      onMounted() {
        this.$nextTick(() => {
          preFetch(this)
          // 手动控制以及直出的组件不受loaded影响,以避免重复渲染
          if(this.emitLoaded || this.lazyForceShow) return
          this.loaded = true
        })
      },
      onLoaded() {
        console.log('onLoaded')
        this.$nextTick(() => {
          this.loaded = true
        })
      },
    },
    render(h) {
      if (this.isLazy && !this.lazyForceShow) {
        return h('div', this.renderOptions)
      }
      const component = h(this.Nonce, {
        props: this.$options.propsData,
        attrs: this.renderOptions?.attrs,
        on: this.renderOptions?.on,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
      return h('div', {
        style: !this.loaded && this.renderOptions?.style && !this.lazyForceShow ? { height: 0, ...this.renderOptions?.style } : {},
      }, [component])
    },
  })
  
  return result
}
