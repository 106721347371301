import { ref, onMounted, onUnmounted } from 'vue'

export const FollowBtnScene = {
  DRAWER_FOOTER: 5,
}

export const useStoreFollowState = ({
  shopData,
  onNonFirstFollow,
}) => {
  const { followsStatus } = shopData || {}
  const isStoreFollowed = ref(!!followsStatus)
  const onFollowStateChange = ({ followState, isFirstTimeFollow, scene }) => {
    isStoreFollowed.value = !!followState
    if (isStoreFollowed.value && !isFirstTimeFollow) {
      // 非首次关注
      onNonFirstFollow?.({ scene })
    }
  }
  onMounted(() => {
    appEventCenter?.$on?.('store_pages:storeFollowStateChange', onFollowStateChange)
  })
  onUnmounted(() => {
    appEventCenter?.$off?.('store_pages:storeFollowStateChange', onFollowStateChange)
  })
  return {
    isStoreFollowed,
  }
}
