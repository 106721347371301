<!-- eslint-disable @shein-aidc/cccx/notUseImgTag -->
<template>
  <div
    v-expose="getAnalysisData(exposeId, { item: cccAnalysisInfo, index: 0 })"
    class="short-shop-banner"
  >
    <section class="short-shop-banner__main">
      <div
        class="short-shop-banner__left"
        @click.stop="showDescriptionDrawer"
      >
        <div class="short-shop-banner__store-logo-wrapper">
          <BaseImg
            v-if="shopData.logo"
            class="short-shop-banner__store-logo fsp-element"
            :first-screen="true"
            :is-support-webp="true"
            :img-design-width="40"
            :img-src="shopData.logo"
          />
        </div>
        <div class="short-shop-banner__store-info">
          <p
            v-if="shopData.title"
            class="short-shop-banner__store-title"
          >
            {{ shopData.title }}
            <i class="short-shop-banner__store-title-arrow"></i>
          </p>
          <div
            v-if="formattedData.hasDataLabels || storeScores"
            class="short-shop-banner__store-labels"
          >
            <ShortBannerDataLabels
              :constants-data="{ PUBLIC_CDN }"
              :store-banner-lang="storeBannerLang"
              :shop-data="shopData"
              :formatted-data="formattedData"
            />
            <div
              v-if="showLabelLineSplitDot"
              class="short-shop-banner__split-dot"
            >
              ·
            </div>
            <div
              v-if="storeScores"
              class="short-shop-banner__store-scores"
            >
              <i class="short-shop-banner__score-star"></i>
              <span>{{ storeScores }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="short-shop-banner__right">
        <StoreFollowBtn
          ref="storeFollowBtnRef"
          class="short-shop-banner__follow-btn-wrapper"
          v-bind="$props"
          @hook:mounted="handleFollowBtnUpdated"
          @hook:updated="handleFollowBtnUpdated" 
        >
          <template
            slot="button"
            slot-scope="slotProps"
          >
            <div
              v-if="slotProps.shopData?.followsStatus"
              class="short-shop-banner__followed-btn"
              :style="{minWidth: storeFollowBtnMinWidth + 'px'}"
            >
              <i class="short-shop-banner__followed-icon"></i>
              <p>{{ storeBannerLang?.followedLang }}</p>
            </div>
            <div
              v-else
              class="short-shop-banner__follow-btn"
              :style="{minWidth: storeFollowBtnMinWidth + 'px'}"
            >
              <p class="short-shop-banner__follow-text">
                <svg class="short-shop-banner__follow-icon" xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.87496 6.6252V2.60425H6.12496V6.6252H2.10547V8.3752H6.12496V12.3958H7.87496V8.3752H11.8971V6.6252H7.87496Z" fill="black"/>
                  <path d="M7.87496 2.60425H8.12496V2.35425H7.87496V2.60425ZM7.87496 6.6252H7.62496V6.8752H7.87496V6.6252ZM6.12496 2.60425V2.35425H5.87496V2.60425H6.12496ZM6.12496 6.6252V6.8752H6.37496V6.6252H6.12496ZM2.10547 6.6252V6.3752H1.85547V6.6252H2.10547ZM2.10547 8.3752H1.85547V8.6252H2.10547V8.3752ZM6.12496 8.3752H6.37496V8.1252H6.12496V8.3752ZM6.12496 12.3958H5.87496V12.6458H6.12496V12.3958ZM7.87496 12.3958V12.6458H8.12496V12.3958H7.87496ZM7.87496 8.3752V8.1252H7.62496V8.3752H7.87496ZM11.8971 8.3752V8.6252H12.1471V8.3752H11.8971ZM11.8971 6.6252H12.1471V6.3752H11.8971V6.6252ZM7.62496 2.60425V6.6252H8.12496V2.60425H7.62496ZM6.12496 2.85425H7.87496V2.35425H6.12496V2.85425ZM6.37496 6.6252V2.60425H5.87496V6.6252H6.37496ZM2.10547 6.8752H6.12496V6.3752H2.10547V6.8752ZM2.35547 8.3752V6.6252H1.85547V8.3752H2.35547ZM6.12496 8.1252H2.10547V8.6252H6.12496V8.1252ZM6.37496 12.3958V8.3752H5.87496V12.3958H6.37496ZM7.87496 12.1458H6.12496V12.6458H7.87496V12.1458ZM7.62496 8.3752V12.3958H8.12496V8.3752H7.62496ZM11.8971 8.1252H7.87496V8.6252H11.8971V8.1252ZM11.6471 6.6252V8.3752H12.1471V6.6252H11.6471ZM7.87496 6.8752H11.8971V6.3752H7.87496V6.8752Z" fill="black"/>
                </svg>
              {{ storeBannerLang.followLang }}
              </p>
              <p
                v-if="shopData.followsCount && shopData.followsCount !== '0'"
                class="short-shop-banner__following"
              >
                {{ shopData.followsCount }}
                {{ storeBannerLang.followsCountLang }}
              </p>
            </div>
          </template>
        </StoreFollowBtn>
      </div>
    </section>
    <!-- 服务标签和描述 -->
    <ServicePolicyLabels
      :constants-data="constantsData"
      :shop-data="shopData"
      :formatted-data="formattedData"
      @show-description-drawer="showDescriptionDrawer"
    />
  </div>
</template>

<script>
import mixins from '../mixins/mixin'

export default {
  mixins: [mixins],
}
</script>

<script setup name="ShortShopBanner">
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import { ref, computed } from 'vue'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props'
import ShortBannerDataLabels from './widgets/ShortBannerDataLabels.vue'
import StoreFollowBtn from './widgets/StoreFollowBtn.vue'
import ServicePolicyLabels from './widgets/ServicePolicyLabels.vue'

const { PUBLIC_CDN = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

const props = defineProps({
  ...commonProps,
  formattedData: {
    type: Object,
    default: () => ({}),
  },
})
const constantsData = ref({
  PUBLIC_CDN,
})
const storeFollowBtnRef = ref(null)
const storeFollowBtnMinWidth = ref(82)
const cccAnalysisInfo = computed(() => props.formattedData?.cccAnalysisInfo ?? {})
const shopData = computed(() => props.propData?.storeBanner || {})
const storeBannerLang = computed(() => props.context?.language?.storeBanner || {})
const storeScores = computed(() => props.formattedData?.storeScores)
const showLabelLineSplitDot = computed(() => props.formattedData?.hasDataLabels && !!storeScores.value)
const exposeId = computed(() => props.formattedData?.isCCCshopBanner ? '2-22-1' : '2-22-77')

const emits = defineEmits([
  'show-description-drawer',
])
const showDescriptionDrawer = () => {
  emits('show-description-drawer')
}

const handleFollowBtnUpdated = () => {
  if(storeFollowBtnRef.value?.$el?.firstElementChild){
    const width = storeFollowBtnRef.value.$el.firstElementChild.offsetWidth
    storeFollowBtnMinWidth.value = Math.max(storeFollowBtnMinWidth.value, width)
  }
}
</script>

<style lang="less">
.short-shop-banner {
  padding: 8/37.5rem 12/37.5rem;
  &__main {
    display: flex;
    align-items: center;
  }
  &__left {
    flex: 1;
    display: flex;
    align-items: center;
  }
  &__right {
    margin-left: 12/37.5rem;
    flex-shrink: 0;
  }

  &__store-logo-wrapper {
    width: 36/37.5rem;
    height: 36/37.5rem;
    overflow: hidden;
    border-radius: 4/37.5rem;
    background-color: #fff;
    .short-shop-banner__store-logo {
      width: 36/37.5rem;
      display: block;
    }
  }

  &__store-info {
    flex: 1;
    margin-left: 8/37.5rem;
  }

  &__store-title {
    display: flex;
    align-items: center;
    line-height: 1.166667;
    font-size: 12px;
    font-weight: 700;
    font-family: 'SF-UI-Text-Bold';
    color: #fff;
    .short-shop-banner__store-title-arrow {
      display: block;
      width: 12/37.5rem;
      height: 12/37.5rem;
      background: url(https://img.ltwebstatic.com/images3_ccc/2024/06/03/35/1717416954b0e71f2ac772a82d1d2f86994c2186fa.png) 50% 50% / contain no-repeat;
      margin-left: 2/37.5rem;
    }
    & + .short-shop-banner__store-labels {
      margin-top: 6/37.5rem;
    }
  }

  &__store-labels {
    display: flex;
    align-items: center;
  }

  &__split-dot {
    margin: 0 4/37.5rem;
    line-height: 1;
    font-size: 11px;
    font-weight: 500;
    font-family: 'SF-UI-Text-Bold';
    color: #fff;
  }

  &__store-scores {
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 11px;
    font-weight: 500;
    font-family: 'SF-UI-Text-Bold';
    color: #fff;
    span {
      padding-top: 1/37.5rem;
    }
    .short-shop-banner__score-star {
      display: block;
      width: 12/37.5rem;
      height: 12/37.5rem;
      margin-right: 2/37.5rem;
      background: url(https://img.ltwebstatic.com/images3_ccc/2024/06/03/b8/1717417207faa5daca67cb7531743595173314c82c.png) 50% 50% / contain no-repeat;
    }
  }

  &__followed-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6/37.5rem;
    height: 32/37.5rem;
    min-width: 2.186667rem /* 82/37.5 */;
    border-radius: 4/37.5rem;
    border: 0.5px solid rgba(255, 255, 255, 0.50);
    font-size: 12px;
    font-family: 'SF-UI-Text-Regular';
    line-height: 1.166667;
    color: #CCC;
    .short-shop-banner__followed-icon {
      display: block;
      width: 10/37.5rem;
      height: 10/37.5rem;
      margin-right: 2/37.5rem;
      background: url(https://img.ltwebstatic.com/images3_ccc/2024/06/04/5e/17174858543e34611b8b86886dfc54f806963e72fb.png) 50% 50% / contain no-repeat;
    }
  }

  &__follow-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 6/37.5rem;
    height: .853333rem /* 32/37.5 */;
    min-width: 2.186667rem /* 82/37.5 */;
    border-radius: 4/37.5rem;
    background-color: #fff;
    .short-shop-banner__follow-icon {
      margin-right: 2/37.5rem;
    }
    .short-shop-banner__follow-text {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: Bold;
      font-family: 'SF-UI-Text-Bold';
      color: #000;
      line-height: .4rem /* 15/37.5 */;
    }
    .short-shop-banner__following {
      font-size: .24rem /* 9/37.5 */;
      font-family: 'SF-UI-Text-Regular';
      color: #666;
      line-height: .24rem /* 9/37.5 */;
      font-weight: normal;
    }
  }
}

[mir=rtl] {
  .short-shop-banner__store-title-arrow {
    transform: rotateZ(180deg);
  }
}
</style>
