<template>
  <div
    v-if="showBannerLabel"
    class="branch-shop-banner-lablels-container"
    :class="{
      'in-short-shopbanner': formattedData.showShortShopBanner,
    }"
    @click.stop="onLabelClick"
  >
    <div class="branch-shop-banner-lablels-left">
      <div
        ref="bannerLabelRef"
        v-expose="{
          id: '2-22-65',
          data: {
            storeBannerLabel,
          },
        }"
        class="branch-shop-banner-tagsline fsp-element"
      >
        <div
          v-for="servicePolicyLabelItem in storeBannerLabel"
          :key="servicePolicyLabelItem.type"
          v-tap="{
            id: '2-22-66',
            data: {
              label_content: servicePolicyLabelItem.type,
            },
          }"
          class="tagsline-item"
        >
          <!-- eslint-disable @shein-aidc/cccx/notUseImgTag -->
          <img
            class="tagsline-item__img"
            :src="`${constantsData.PUBLIC_CDN}/pwa_dist/images/ccc/shop_banner_labels/${servicePolicyLabelItem.type}.png`"
          />
          <div class="tagsline-item__desc">
            {{ servicePolicyLabelItem.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup name="StoreServicePolicyLabels">
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter'

const props = defineProps({
  constantsData: {
    type: Object,
    default: () => ({}),
  },
  shopData: {
    type: Object,
    default: () => ({}),
  },
  formattedData: {
    type: Object,
    default: () => ({}),
  },
  hideDescription: {
    type: Boolean,
    default: false,
  },
})

const storeBannerLabel = computed(() => props.formattedData.bannerLabel)
const showBannerLabel = computed(() => storeBannerLabel.value?.length > 0)

let isLabelScrolling = false
let bannerLabelScrollStoped = false
const bannerLabelRef = ref(null)
const startBannerLabelScroll = () => {
  const bannerLabelElement = bannerLabelRef.value
  const scrollWidth = bannerLabelElement.scrollWidth
  let scrollLeft = 0
  let startTime = null
  const duration = 50 // Set the duration in milliseconds
  const step = (timestamp) => {
    if (!startTime) {
      startTime = timestamp
    }
    if(timestamp - startTime > duration) {
      scrollLeft = scrollLeft + 3 / 375 * 100
      bannerLabelElement.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      })
      startTime = timestamp
    }
    if (scrollLeft < scrollWidth && !bannerLabelScrollStoped) {
      requestAnimationFrame(step)
      if (!isLabelScrolling) {
        bannerLabelRef.value?.addEventListener('touchstart', stopBannerLabelScroll)
        window.addEventListener('scroll', stopBannerLabelScroll)
        isLabelScrolling = true
      }
    } else {
      stopBannerLabelScroll()
    }
  }

  requestAnimationFrame(step)
}

const stopBannerLabelScroll = () => {
  bannerLabelScrollStoped = true
}

onMounted(() => {
  setTimeout(() => {
    if(!!bannerLabelRef.value && !props.shopData.isBrandStore) {
      startBannerLabelScroll()
    }
  }, 1000)
})
onUnmounted(() => {
  if (!isLabelScrolling) return
  bannerLabelRef.value?.removeEventListener('touchstart', stopBannerLabelScroll)
  window.removeEventListener('scroll', stopBannerLabelScroll)
})

const emits = defineEmits([
  'show-description-drawer',
])
const onLabelClick = () => {
  emits('show-description-drawer')
  stopBannerLabelScroll()
  daEventCenter.triggerNotice({
    daId: '2-22-67',
    bindData: {
      storeBannerLabel: storeBannerLabel.value,
    },
  })
}
</script>

<style lang="less" scoped>
.branch-shop-banner-lablels-container {
  margin-top: .213333rem /* 8/37.5 */;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  .branch-shop-banner-lablels-left{
    overflow: hidden;
    flex: 1;
    div:last-child{
      margin-bottom: 0;
    }
  }
  .branch-shop-banner-description{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.60);
    font-size: 11px;
  }
  .branch-shop-banner-lablels-right{
    width: .32rem /* 12/37.5 */;
    height: .32rem /* 12/37.5 */;
    flex-shrink: 0;
  }
}
.branch-shop-banner-tagsline {
  display: flex;
  margin-bottom: 4px;
  overflow-x: auto;
  white-space: nowrap;
  align-items: center;
  .tagsline-item{
    display: flex;
    padding: 0 4px;
    border-radius: 2px;
    border: 0.5px solid rgba(255, 255, 255, 0.12);
    background: rgba(255, 255, 255, 0.20);
    height: .48rem /* 18/37.5 */;
    align-items: center;
    &:not(:first-child){
      margin-left: 5px;
    }
    .tagsline-item__img{
      width: 12px;
      height: 12px;
    }
    .tagsline-item__desc{
      color: rgba(255, 255, 255, 0.80);
      margin-left: .106667rem /* 4/37.5 */;
      font-size: .266667rem /* 10/37.5 */;
      line-height: .453333rem /* 17/37.5 */;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.branch-shop-banner-lablels-container {
  &.in-short-shopbanner {
    .branch-shop-banner-tagsline {
      .tagsline-item{
        background: rgba(255, 255, 255, 0.08);
      }
      .tagsline-item__desc{
        color: #fff;
      }
    }
  }
}
</style>
